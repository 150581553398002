import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { UserNameWrapper } from "@/app/AppShellUserDropdown.jsx";
import { useIsSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";

const WrappedUserNameWrapper = styled(UserNameWrapper)`
  .subscriber {
    background: var(--subscriber-gradient);
    width: fit-content;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    // Extra luminosity by request
    filter: brightness(1.3);
  }
`;

export function AppShellUserDropdownNameWrapper({
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const isSubscriber = useIsSubscriber();

  const { t } = useTranslation();

  return (
    <WrappedUserNameWrapper {...rest}>
      {children}
      {isSubscriber && (
        <span className="type-callout--semi subscriber">
          {t("common:subscriber", "Premium")}
        </span>
      )}
    </WrappedUserNameWrapper>
  );
}
