import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { postData } from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { renewSubscription } from "@/feature-subscriber/api.mjs";
import { SUBSCRIBER_PAYMENT_PLAN } from "@/feature-subscriber/constants.mjs";
import { EVENT_PRO_CONVERSION } from "@/feature-subscriber/constants/events.mjs";
import { CreateProSubscriptionModel } from "@/feature-subscriber/model-create-pro-subscription.mjs";
import { PaymentMethodDropdown } from "@/feature-subscriber/PaymentMethodDropdown.jsx";
import {
  useSubscriberPlan,
  useSubscriberSubscription,
} from "@/feature-subscriber/utils/subscriber.mjs";
import {
  createOrRenewSubscription,
  updateSubscription,
} from "@/feature-wallet/actions.mjs";
import { postUpdateSubscription } from "@/feature-wallet/api.mjs";
import { UpdateUserSubscriptionModel } from "@/feature-wallet/models/user-subscription-update.mjs";
import { FormSubmit } from "@/feature-wallet/Shared.jsx";
import { usePaymentMethod } from "@/feature-wallet/utils/payment-methods.mjs";
import {
  getNextChargeAmount,
  getNextChargeDate,
} from "@/feature-wallet/utils/subscription-charges.mjs";
import { hasSubscriptionError } from "@/feature-wallet/utils/subscription-status.mjs";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/shared/Dialog.jsx";
import { devError } from "@/util/dev.mjs";
import { formatDateTime } from "@/util/i18n-helper.mjs";

export function ManageSubscriptionDialogView({
  selectedPaymentMethodId,
  onSelectedPaymentMethod,
  onAddPaymentMethod,

  onCancelSubscription,
  onUpdated,
}: {
  selectedPaymentMethodId?: string | null;
  onAddPaymentMethod: () => void;
  onSelectedPaymentMethod(paymentMethodId: string): void;

  onCancelSubscription(): void;
  onUpdated(): void;
}) {
  const subscription = useSubscriberSubscription();
  const subscriptionPlan = useSubscriberPlan();

  const nextChargeDate = subscription && getNextChargeDate(subscription);
  const currency = subscriptionPlan?.currency ?? "USD";

  const selectedPaymentMethod = usePaymentMethod({
    providerType:
      subscription && "providerType" in subscription
        ? subscription.providerType
        : null,
    paymentMethodId:
      selectedPaymentMethodId ??
      (subscription && "paymentMethodId" in subscription
        ? subscription.paymentMethodId
        : null),
  });

  const [submitState, setSubmitState] = useState<
    "idle" | "submitting" | "error"
  >("idle");

  const updateSubPaymentMethod = useCallback(
    (bearerToken) => {
      return postData(
        postUpdateSubscription({
          paymentMethodId: selectedPaymentMethod?.id,

          plan: SUBSCRIBER_PAYMENT_PLAN[subscriptionPlan?.recurringInterval]
            .planId,
        }),
        UpdateUserSubscriptionModel,
        undefined,
        {
          headers: { Authorization: bearerToken },
        },
      );
    },
    [selectedPaymentMethod?.id, subscriptionPlan?.recurringInterval],
  );

  const onUpdateSubscriptionSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (!subscriptionPlan) {
        return;
      }

      setSubmitState("submitting");

      try {
        const bearerToken = await getBearerToken();
        const subUpdate = await updateSubPaymentMethod(bearerToken);
        if (subUpdate instanceof Error) throw subUpdate;

        updateSubscription(subUpdate);

        onUpdated();

        setSubmitState("idle");
      } catch (e) {
        devError(`Failed to update payment method!`, e);

        setSubmitState("error");

        eventBus.emit(EVENT_ERROR, {
          error: e,

          tags: ["subscription", "update_payment_method"],
        });
      }
    },

    [subscriptionPlan, updateSubPaymentMethod, onUpdated],
  );

  const onResubscribe = useCallback(async () => {
    if (!selectedPaymentMethod || !subscriptionPlan) {
      return;
    }

    setSubmitState("submitting");

    try {
      const bearerToken = await getBearerToken();

      // There seems to be BE bug where updating the payment method as part of the renew sub
      // mutation does not take.
      await updateSubPaymentMethod(bearerToken);

      const renewedSubscription = await postData(
        renewSubscription({
          plan: SUBSCRIBER_PAYMENT_PLAN[subscriptionPlan?.recurringInterval]
            .planId,
          paymentMethodId: selectedPaymentMethod.id,
          providerType: selectedPaymentMethod.providerType,
        }),
        CreateProSubscriptionModel,
        undefined,
        { headers: { Authorization: bearerToken } },
      );

      if (renewedSubscription instanceof Error) throw renewedSubscription;

      createOrRenewSubscription(renewedSubscription);

      eventBus.emit(EVENT_PRO_CONVERSION, {
        providerType: selectedPaymentMethod.providerType,
      });

      onUpdated();

      setSubmitState("idle");
    } catch (e) {
      // TODO: better error communication
      devError("Error creating premium subscription", e);

      setSubmitState("error");

      eventBus.emit(EVENT_ERROR, {
        error: e,
        tags: ["subscription", "subscription_subscribe"],
      });
    }
  }, [
    onUpdated,
    selectedPaymentMethod,
    subscriptionPlan,
    updateSubPaymentMethod,
  ]);

  const onTryAgain = useCallback(() => {
    setSubmitState("idle");
  }, []);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const subscriptionProviderType =
    subscription && "providerType" in subscription
      ? subscription.providerType
      : null;

  // PayPal and Crypto subscriptions cannot be restarted once they're cancelled
  const shouldAllowResubscribe = subscriptionProviderType === "STRIPE";

  return (
    <DialogContent>
      <DialogHeader
        title={["common:wallet.manageSubscription", "Manage Subscription"]}
      />

      <Form onSubmit={onUpdateSubscriptionSubmit}>
        <DialogBody>
          {subscription && (
            <div>
              <div className="next-payment">
                <div className="line-item">
                  {!subscription.willCancelAtPeriodEnd ? (
                    <>
                      <div className="type-subtitle--semi">
                        {t("common:wallet.nextPayment", "Next Payment")}
                      </div>
                      <div className="type-subtitle--semi">
                        {subscriptionPlan &&
                          t(
                            "common:wallet.renewalPriceOnDate",
                            "{{price, currency}} on {{date, datetime}}",
                            {
                              price:
                                getNextChargeAmount({
                                  subscription,
                                  paymentPlan: subscriptionPlan,
                                }) / 100,
                              date: nextChargeDate,
                              formatParams: { currency },
                            },
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="type-subtitle--semi">
                        {t("common:wallet.subEndsOn", "Ends On")}
                      </div>
                      <div className="type-caption">
                        {formatDateTime(language, nextChargeDate)}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <hr />

              <div className="line-item select-payment-method">
                <div className="type-subtitle--semi">
                  {t("common:subscriber.purchaseModal.payment", "Payment")}
                </div>
                <div className="flex justify-end">
                  <PaymentMethodDropdown
                    name="payment-method"
                    readOnly={subscriptionProviderType === "BRAINTREE"}
                    disabled={submitState === "submitting"}
                    // It's only valid to switch payment methods within the same provider, currently
                    allowedPaymentProviders={subscriptionProviderType}
                    selectedPaymentMethodId={selectedPaymentMethod?.id}
                    onSelectedPaymentMethod={onSelectedPaymentMethod}
                    onAddPaymentMethod={onAddPaymentMethod}
                  />
                </div>
              </div>

              {hasSubscriptionError(subscription) && (
                <div className="error type-body2-form--bold">
                  {t(
                    "common:wallet.manageSubscriberError",
                    "There's been an error processing the payment for your Premium subscription. Please review your payment method.",
                  )}
                </div>
              )}
            </div>
          )}
        </DialogBody>

        <DialogFooter>
          {submitState === "error" ? (
            <FormSubmitContainer>
              <FormSubmit.Error size="medium" onClick={onTryAgain} />
            </FormSubmitContainer>
          ) : submitState === "submitting" ? (
            <FormSubmitContainer>
              <FormSubmit.Loading />
            </FormSubmitContainer>
          ) : subscription?.willCancelAtPeriodEnd ? (
            <Button
              emphasis="high"
              block
              onClick={onResubscribe}
              {...(!shouldAllowResubscribe
                ? {
                    disabled: true,
                    title: t(
                      "common:wallet.cannotResubscribe.title",
                      "Cannot restart Premium Subscription",
                    ),
                    "data-tip": t(
                      "common:wallet.cannotResubscribe.reason",
                      "PayPal subscriptions cannot currently be restarted until the end of the period.",
                    ),
                  }
                : {})}
            >
              {t("common:wallet.resubscribe", "Resubscribe to Premium")}
            </Button>
          ) : (
            <>
              {subscriptionProviderType !== "BRAINTREE" && (
                <Button
                  type="submit"
                  emphasis="high"
                  block
                  onClick={onUpdateSubscriptionSubmit}
                >
                  {t("common:wallet.updateSubscription", "Update Subscription")}
                </Button>
              )}

              <Button
                size="small"
                emphasis="low"
                block
                onClick={onCancelSubscription}
              >
                {t("common:wallet.cancelSubscription", "Cancel Subscription")}
              </Button>
            </>
          )}
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}

const Form = styled("form")`
  hr {
    width: 100%;
    height: var(--sp-px);
    margin: 0;

    border: none;
    border-top: var(--sp-px) solid var(--shade3-15);

    outline: none;
  }

  button[data-emphasis="high"] {
    & {
      --bg: var(--shade0) !important;
      --border: transparent !important;
      color: var(--shade7) !important;
      background-image: none !important;
    }

    &:hover,
    &:focus {
      --bg: hsla(0deg 0% 100% / 0.85) !important;
    }
  }

  .line-item {
    display: flex;
    justify-content: space-between;
    gap: var(--sp-4);

    & > *:first-child {
      color: var(--shade2);
    }

    & > *:nth-child(2) {
      color: var(--shade0);
    }
  }

  .next-payment {
    .line-item {
      padding-block: var(--sp-3);
    }
  }

  .select-payment-method {
    align-items: center;
  }

  .error {
    color: var(--red);
  }

  footer {
    flex-direction: column;
  }
`;

export const FormSubmitContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;

  button {
    margin-top: var(--sp-6);
  }
`;
