import type { DeepReadonly } from "ts-essentials";

import { COUPON_DURATION } from "@/feature-subscriber/constants.mjs";
import type { PaymentPlan } from "@/feature-wallet/models/payment-plans.mjs";
import type { UserSubscription } from "@/feature-wallet/models/user-subscriptions.mjs";

export function getPriceWithCoupon(
  price: number,
  coupon: UserSubscription["coupon"],
) {
  if (coupon.amountOff) return price - coupon.amountOff;
  return price * ((100 - coupon.percentOff) / 100);
}

type SubscriptionWithCoupon = DeepReadonly<Pick<UserSubscription, "coupon">>;

export function getActiveCoupon(
  subscription: SubscriptionWithCoupon,
): SubscriptionWithCoupon["coupon"] {
  return subscription.coupon && new Date() < subscription.coupon.endAt
    ? subscription.coupon
    : null;
}

export function getCouponTranslation({
  activeCoupon: { amountOff, percentOff, duration, endAt },
  paymentPlan,
}: {
  activeCoupon: UserSubscription["coupon"];
  paymentPlan: PaymentPlan;
}): Translation {
  amountOff ??= 0;
  percentOff ??= 0;

  let amountOffText: Translation = [
    "common:wallet.couponPercentOff",
    "{{pc, percent}} off",
    {
      pc: percentOff / 100,
    },
  ];

  if (percentOff === 100 || paymentPlan.price - amountOff === 0) {
    amountOffText = ["common:free", "Free"];
  } else if (amountOff) {
    amountOffText = [
      "common.wallet.couponAmountOff",
      "{{amountOff, currency}} off",
      {
        amountOff: amountOff / 100,
        formatParams: { currency: paymentPlan.currency },
      },
    ];
  }

  if (duration === COUPON_DURATION.FOREVER) {
    return amountOffText;
  }

  return [
    "common:wallet.couponPeriod",
    "{{amountOffText}} until {{couponEnd, datetime}}",
    {
      amountOffText,
      couponEnd: endAt,
    },
  ];
}
