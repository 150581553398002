import React from "react";
import { styled } from "goober";

import { useIsSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import { ImageContainer } from "@/hub-settings/UserInfo.style.jsx";
import { classNames } from "@/util/class-names.mjs";

const WrappedImageContainer = styled(ImageContainer)`
  &.subscriber {
    color: var(--subscriber);

    & > svg {
      z-index: 1;
    }

    &::before {
      background: var(--subscriber-gradient-15);
    }
  }
`;

export function SettingsUserImageContainer({
  children,
}: React.PropsWithChildren) {
  const isSubscriber = useIsSubscriber();

  return (
    <WrappedImageContainer {...classNames(isSubscriber && "subscriber")}>
      {children}
    </WrappedImageContainer>
  );
}
