import type { DeepReadonly } from "ts-essentials";

import type { PaymentPlan } from "@/feature-wallet/models/payment-plans.mjs";
import type { UserSubscription } from "@/feature-wallet/models/user-subscriptions.mjs";
import {
  getActiveCoupon,
  getPriceWithCoupon,
} from "@/feature-wallet/utils/coupons.mjs";
import { isTrialSubscription } from "@/feature-wallet/utils/subscription-status.mjs";

type SubscriptionForNextChargeDate = DeepReadonly<
  Pick<UserSubscription, "status" | "trialEnd" | "currentPeriodEnd">
>;

export function getNextChargeDate(
  subscription: SubscriptionForNextChargeDate,
): Date {
  return "trialEnd" in subscription && isTrialSubscription(subscription)
    ? subscription.trialEnd
    : subscription.currentPeriodEnd;
}

type SubscriptionForNextChargeAmount = DeepReadonly<
  Pick<UserSubscription, "status" | "trialEnd" | "coupon">
>;

export function getNextChargeAmount({
  subscription,
  paymentPlan,
}: {
  subscription: SubscriptionForNextChargeAmount;
  paymentPlan: PaymentPlan;
}) {
  const activeCoupon = getActiveCoupon(subscription);

  return activeCoupon
    ? getPriceWithCoupon(paymentPlan.price, activeCoupon)
    : paymentPlan.price;
}
