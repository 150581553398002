import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { setRoute } from "@/__main__/router.mjs";
import { appURLs } from "@/app/constants.mjs";
import { ManageSubscriptionDialog } from "@/feature-subscriber/ManageSubscriptionDialog.jsx";
import {
  useIsSubscriber,
  useSubscriberSubscription,
} from "@/feature-subscriber/utils/subscriber.mjs";
import { PortfolioTile } from "@/feature-wallet/PortfolioTile.jsx";
import { getNextChargeDate } from "@/feature-wallet/utils/subscription-charges.mjs";
import { hasSubscriptionError } from "@/feature-wallet/utils/subscription-status.mjs";
import Add from "@/inline-assets/add.svg";
import Check from "@/inline-assets/check.svg";
import ExclamationIcon from "@/inline-assets/exclamation-mark.svg";
import FullscreenOverlay from "@/shared/FullscreenOverlay.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";

const Styled = {
  tileCss() {
    return css`
      &:hover {
        & .video {
          filter: grayscale(0%);
          opacity: 1;
        }
      }

      &.has-subscriber {
        --subscriber-hsl: 38deg 48% 55%;
        --bg-color: hsl(var(--subscriber-hsl) / 0.35);
        --hover-bg-color: hsl(var(--subscriber-hsl) / 0.45);
        --hover-text-color: var(--subscriber);
        --wrapper-bg-color: hsl(var(--subscriber-hsl) / 0.15);
        & .video {
          filter: grayscale(0%);
          opacity: 1;
        }
      }

      &.has-error {
        --text-color: var(--red);
        --hover-text-color: var(--red);
      }

      & .video {
        position: absolute;
        right: calc(-1 * var(--sp-6));

        filter: grayscale(100%);
        opacity: 0.3;
      }
    `;
  },
};

export function ProTile() {
  const videoRef = useRef<HTMLVideoElement>();

  const isLoaded = useIsLoaded();

  const isSubscriber = useIsSubscriber();
  const subscription = useSubscriberSubscription();
  const hasError = subscription && hasSubscriptionError(subscription);

  const isStubSubscription =
    isSubscriber && subscription && !("id" in subscription);
  const hasComplementarySubscription = isSubscriber && !subscription;

  const canManageSubscription =
    !hasComplementarySubscription && !isStubSubscription;

  // If a bug occurs and the role isn't applied, but there is an active subscription
  // we still want to show that they have premium, here.
  const hasSubscription = isSubscriber || !!subscription;

  const onMouseOver = useCallback(() => {
    videoRef.current.play();
  }, []);

  const onMouseLeave = useCallback(() => {
    videoRef.current.currentTime = 0;
    videoRef.current.pause();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick = useCallback(() => {
    if (subscription) {
      setIsModalOpen(true);
    } else {
      // TODO: This is a quick fix to send users to `/pro` for sign up
      // instead of having a dedicated dialog flow. Route this properly.
      setRoute("/premium", undefined, undefined, true);
    }
  }, [subscription]);

  const closeManagementModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <PortfolioTile
        {...classNames(
          canManageSubscription && "has-action",
          Styled.tileCss(),
          hasError ? "has-error" : hasSubscription ? "has-subscriber" : "",
        )}
        onClick={canManageSubscription ? onClick : null}
        {...(!hasError &&
          !hasSubscription && {
            onMouseOver,
            onMouseLeave,
          })}
      >
        <div className="action">
          <div className="wrapper">
            {hasError ? (
              <ExclamationIcon />
            ) : hasSubscription ? (
              <Check />
            ) : (
              <Add />
            )}
          </div>
        </div>

        <div className="title type-body1 flex column">
          {hasError ? (
            t("common:wallet.subscriberError", "Error Renewing Premium")
          ) : hasSubscription ? (
            <>
              {t("common:subscriber", "Premium")}

              {(subscription || isLoaded) && (
                <span className="type-form--button shade1">
                  {subscription
                    ? subscription.willCancelAtPeriodEnd
                      ? t(
                          "common:wallet.endsOnDate",
                          "Ends on {{date, datetime}}",
                          { date: getNextChargeDate(subscription) },
                        )
                      : t(
                          "common:wallet.renewsOnDate",
                          "Renews on {{date, datetime}}",
                          { date: getNextChargeDate(subscription) },
                        )
                    : t("common:wallet.renewsNever", "Renews in ∞ years")}
                </span>
              )}
            </>
          ) : (
            t("common:wallet.purchaseSubscriber", "Purchase Premium")
          )}
        </div>

        <div className="video">
          <video
            ref={videoRef}
            className="vid"
            width="160"
            height="auto"
            loop
            muted
            autoPlay={!hasError && isSubscriber}
          >
            <source
              type="video/webm"
              src={`${appURLs.CDN_VIDEOS}/ui/video/blitz-pro-pass-transparent.webm`}
            />
          </video>
        </div>
      </PortfolioTile>

      <FullscreenOverlay
        isOpen={isModalOpen}
        onModalClose={closeManagementModal}
      >
        <ManageSubscriptionDialog closeModal={closeManagementModal} />
      </FullscreenOverlay>
    </>
  );
}
