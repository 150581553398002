import React from "react";

import { UserDropdownWrapper } from "@/app/AppShellUserDropdown.jsx";
import { useIsSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import { classNames } from "@/util/class-names.mjs";

export function AppShellUserDropdownWrapper({
  className,
  children,
  style = {},
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const isSubscriber = useIsSubscriber();

  return (
    <UserDropdownWrapper
      {...classNames(className, isSubscriber && "subscriber")}
      {...rest}
      style={{
        "--user-color": isSubscriber && "var(--subscriber)",
        ...style,
      }}
    >
      {children}
    </UserDropdownWrapper>
  );
}
