import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import addInitialPath from "@/util/add-initial-path.mjs";

export function initProState() {
  addInitialPath(["subscriber"], {
    pricing: null,
  });
}

export function removeProState() {
  for (const key in readState.subscriber) {
    delete writeState.subscriber[key];
  }
  delete writeState.subscriber;
}

export function clearUserData() {
  removeProState();
  initProState();
}
