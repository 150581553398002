import React from "react";
import { styled } from "goober";

import MenuListItem from "@/app/AppShellMenuListItem.jsx";
import { useIsSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import PremiumIcon from "@/inline-assets/pro.svg";
import { classNames } from "@/util/class-names.mjs";

const SubscriberWrapper = styled(MenuListItem)`
  span {
    background: var(--subscriber-gradient);
    background-clip: text;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export function AccountMenuSubscriberItem({ className, onClick }) {
  const isSubscriber = useIsSubscriber();

  return (
    !isSubscriber && (
      <SubscriberWrapper
        href="/premium"
        onClick={onClick}
        text={["common:wallet.getSubscriber", "Get Premium"]}
        Icon={<PremiumIcon />}
        {...classNames(className)}
      />
    )
  );
}
