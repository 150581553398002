import type { DeepReadonly } from "ts-essentials";

import type { User } from "@/feature-auth/models/user-model.mjs";
import { proxy } from "@/util/proxy.mjs";

interface AccountCardRefs {
  UserTag: React.FC<{
    user: DeepReadonly<Pick<User, "id" | "email" | "name" | "roles">>;
  }>;
}

export const accountCardRefs = proxy<AccountCardRefs>({
  UserTag: null,
});
