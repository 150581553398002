import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { TextField } from "clutch/src/TextField/TextField.jsx";

import { postData } from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { hardCodeURLs } from "@/app/constants.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import {
  CANCEL_FEATURES,
  CANCEL_REASONS,
  SUBSCRIBER_PAYMENT_PLAN,
} from "@/feature-subscriber/constants.mjs";
import {
  useSubscriberPlan,
  useSubscriberSubscription,
} from "@/feature-subscriber/utils/subscriber.mjs";
import { updateSubscription } from "@/feature-wallet/actions.mjs";
import { postCancelSubscription } from "@/feature-wallet/api.mjs";
import { CancelUserSubscriptionModel } from "@/feature-wallet/models/user-subscription-cancel.mjs";
import { getNextChargeDate } from "@/feature-wallet/utils/subscription-charges.mjs";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/shared/Dialog.jsx";
import {
  createModalNavigation,
  useModalNavigation,
} from "@/shared/ModalNavigation.jsx";
import { devError } from "@/util/dev.mjs";

const CancelSubscriptionNav = createModalNavigation<{
  confirmCancellation: void;
  giveFeedback: void;
  cancelFailed: void;
}>("purchase-premium");

export function CancelSubscriptionDialogView({ onReturn, onCancelled }) {
  const modalRouter = useModalNavigation(
    CancelSubscriptionNav,
    "confirmCancellation",
  );

  const goFeedback = useCallback(() => {
    modalRouter.pushRoute("giveFeedback");
  }, [modalRouter]);

  const onCancelFailed = useCallback(() => {
    modalRouter.pushRoute("cancelFailed");
  }, [modalRouter]);

  return (
    <CancelSubscriptionNav.Navigator
      modalRouter={modalRouter}
      onPopScope={onReturn}
    >
      <CancelSubscriptionNav.Route name="confirmCancellation">
        <ConfirmCancelView onChangedMind={onReturn} onNext={goFeedback} />
      </CancelSubscriptionNav.Route>

      <CancelSubscriptionNav.Route name="giveFeedback">
        <GiveFeedbackView
          onChangedMind={onReturn}
          onCancelled={onCancelled}
          onCancelFailed={onCancelFailed}
        />
      </CancelSubscriptionNav.Route>

      <CancelSubscriptionNav.Route name="cancelFailed">
        <CancelFailedView onBack={onReturn} />
      </CancelSubscriptionNav.Route>
    </CancelSubscriptionNav.Navigator>
  );
}

function ConfirmCancelView({
  onChangedMind,
  onNext,
}: {
  onChangedMind(): void;
  onNext(): void;
}) {
  const subscription = useSubscriberSubscription();

  const nextChargeDate = subscription && getNextChargeDate(subscription);

  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogHeader
        title={["common:areYouSure", "Are you sure?"]}
        onPressBack={onChangedMind}
      />

      <CancelSubscriptionDialogBody>
        <p className="type-body2 shade1 text-center">
          {t(
            "common:wallet.cancelDowngradeText",
            `Your account will downgrade to Blitz Lite on {{date, datetime}}. After that, you'll no longer have access to Premium features including:`,
            { date: nextChargeDate },
          )}
        </p>

        <ul className="features type-body2">
          {CANCEL_FEATURES.map(({ Icon, text }, i) => (
            <li key={i}>
              <Icon /> {t(...text)}
            </li>
          ))}
        </ul>
      </CancelSubscriptionDialogBody>

      <DialogFooter>
        <Button size="large" block onClick={onChangedMind}>
          {t("common:wallet.dontCancel", "Don't Cancel")}
        </Button>

        <Button size="large" emphasis="low" block onClick={onNext}>
          {t("common:next", "Next")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

const CancelSubscriptionDialogBody = styled(DialogBody)`
  display: flex;
  flex-direction: column;

  gap: var(--sp-4);

  ul.features {
    display: grid;
    grid-template-columns: repeat(${CANCEL_FEATURES.length}, 1fr);

    color: var(--shade1);

    text-align: center;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-2);
    }
  }
`;

const NO_REASON_GIVEN = "no-reason-given";

function GiveFeedbackView({
  onChangedMind,
  onCancelled,
  onCancelFailed,
}: {
  onChangedMind(): void;
  onCancelled(): void;
  onCancelFailed(): void;
}) {
  const subscriptionPlan = useSubscriberPlan();

  const [userCancelReason, setUserReason] = useState("");
  const [reasonIndex, setReasonIndex] = useState(null);

  const onUserCancelReasonChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserReason(e.target.value);
    },
    [setUserReason],
  );

  const [isLoading, setIsLoading] = useState(false);

  const onConfirmCancellation = useCallback(async () => {
    if (!subscriptionPlan) {
      return;
    }

    setIsLoading(true);

    const cancellationReason =
      reasonIndex !== null
        ? reasonIndex !== CANCEL_REASONS.length - 1
          ? CANCEL_REASONS[reasonIndex].text[1]
          : userCancelReason
        : NO_REASON_GIVEN;

    try {
      const bearerToken = await getBearerToken();
      const subUpdate = await postData(
        postCancelSubscription({
          cancellationReason,

          plan: SUBSCRIBER_PAYMENT_PLAN[subscriptionPlan.recurringInterval]
            .planId,
        }),
        CancelUserSubscriptionModel,
        undefined,
        {
          headers: { Authorization: bearerToken },
        },
      );

      if (subUpdate instanceof Error) throw subUpdate;

      updateSubscription(subUpdate);
      onCancelled();
    } catch (e: unknown) {
      devError("Subscription cancellation failed!", e);

      onCancelFailed();

      eventBus.emit(EVENT_ERROR, {
        error: e,
        tags: ["subscription", "cancel_subscription"],
      });
    }

    setIsLoading(false);
  }, [
    reasonIndex,
    userCancelReason,
    subscriptionPlan,
    onCancelled,
    onCancelFailed,
  ]);

  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogHeader
        title={["common:wallet.cancelReason", "Could you tell us why?"]}
        onPressBack={onChangedMind}
      />

      <GiveFeedbackDialogBody>
        <p className="type-body2 shade1 text-center">
          {t(
            "common:wallet.cancelImproveText",
            "We are always looking for ways to improve, your feedback will help us improve Premium in the future",
          )}
        </p>

        <fieldset className="reasons">
          <legend className="visually-hidden">
            {t("common:wallet.selectReason", "Select a reason (optional)")}
          </legend>

          {CANCEL_REASONS.map(({ Icon, text }, i) => {
            return (
              <div key={i} className="display-contents">
                <input
                  type="radio"
                  id={`reason-${i}`}
                  onChange={() => setReasonIndex(i)}
                  // onClick={() => setReasonIndex(i)}
                  checked={i === reasonIndex}
                  disabled={isLoading}
                />
                <label
                  htmlFor={`reason-${i}`}
                  className="type-form--button flex column align-center justify-end gap-2"
                >
                  <Icon /> {t(...text)}
                </label>
              </div>
            );
          })}
        </fieldset>

        {reasonIndex === CANCEL_REASONS.length - 1 && (
          <div className="input-wrapper w-full flex column gap-2">
            <label className="type-subtitle2" htmlFor="reason">
              {t("common:wallet.specify", `Please specify:`)}
            </label>

            <TextField
              type="text"
              name="reason"
              onChange={onUserCancelReasonChanged}
              autoFocus
              maxLength={200}
              placeholder={t("common:wallet.otherReason", "Other Reason...")}
              disabled={isLoading}
            />
          </div>
        )}
      </GiveFeedbackDialogBody>

      <DialogFooter>
        <Button size="large" block onClick={onChangedMind} disabled={isLoading}>
          {t("common:wallet.dontCancel", "Don't Cancel")}
        </Button>

        <Button
          size="large"
          emphasis="low"
          block
          bgColorHover="var(--red-25)"
          onClick={onConfirmCancellation}
          disabled={isLoading}
        >
          {t("common:wallet.cancelSubscription", "Cancel Subscription")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

const GiveFeedbackDialogBody = styled(DialogBody)`
  display: flex;
  flex-direction: column;

  gap: var(--sp-4);

  .reasons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--sp-3);

    width: 100%;
    padding: 0;

    border: none;

    input[type="radio"] {
      position: absolute;

      opacity: 0;

      + label {
        padding: var(--sp-4);

        border-radius: var(--br);

        color: var(--shade1);
        background-color: var(--shade6);

        text-align: center;

        cursor: pointer;

        &:hover {
          color: var(--shade0);
          background-color: var(--shade5);
        }
      }

      &:checked {
        + label {
          background-color: hsla(var(--blue-hsl) / 0.15);
        }
      }
    }
  }

  .input-wrapper {
    input {
      width: 100%;
      padding: var(--sp-2);

      color: var(--shade1);

      background-color: var(--shade8);
      border: 1px solid var(--shade6);
      border-radius: var(--br);
    }
  }
`;

function CancelFailedView({ onBack }: { onBack(): void }) {
  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogHeader
        title={["common:wallet.cancellationFailed.title", "An error occurred"]}
        onPressBack={onBack}
      />

      <DialogBody>
        <p className="type-body2 shade1 text-center">
          {t(
            "common:wallet.subscriptionCancelledFailed",
            "Sorry, something went wrong and we couldn't cancel your subscription. Please refresh and try again!",
          )}
        </p>
      </DialogBody>

      <DialogFooter>
        <Button
          as="a"
          size="large"
          block
          href="mailto:support@blitz.gg?subject=Plan/Billing Issue"
          target="_blank"
        >
          {t("common:wallet.contactSupport", "Contact Support")}
        </Button>

        <Button
          as="a"
          size="large"
          block
          href={hardCodeURLs.BLITZ_DISCORD}
          target="_blank"
        >
          {t("common:wallet.discord", "Support Discord")}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
