import createModel from "@/__main__/data-model.mjs";
import {
  normalizeUserSubscription,
  userSubscriptionModel,
} from "@/feature-wallet/models/user-subscriptions.mjs";

export const CancelUserSubscriptionModel = createModel({
  data: { unsubscribeFromPlan: userSubscriptionModel },
}).transform(userSubscriptionModel, (data) =>
  normalizeUserSubscription(data.data.unsubscribeFromPlan),
);
