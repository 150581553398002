import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import {
  useIsSubscriber,
  useSubscriberError,
} from "@/feature-subscriber/utils/subscriber.mjs";

const SubscriberTag = styled(Tag)`
  height: fit-content;
`;

export function UserInfoTag() {
  const isSubscriber = useIsSubscriber();

  const hasSubscriberError = useSubscriberError();

  const { t } = useTranslation();

  if (hasSubscriberError)
    return (
      <SubscriberTag
        as="a"
        href="/wallet/portfolio"
        text={t("common:subscriber", "Premium")}
        color="var(--red)"
      />
    );

  return (
    isSubscriber && (
      <SubscriberTag
        as="a"
        href="/wallet/portfolio"
        text={t("common:subscriber", "Premium")}
        color="var(--subscriber-solid)"
      />
    )
  );
}
