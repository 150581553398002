import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { isUserSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";

import type { User } from "../../test/integration/auth-sign-in.mjs";

const SubscriberTag = styled(Tag)`
  height: fit-content;
`;

export function AccountCardUserTag({ user }: { user: User }) {
  const isSubscriber = isUserSubscriber(user);

  const { t } = useTranslation();

  return (
    isSubscriber && (
      <SubscriberTag
        text={t("common:subscriber", "Premium")}
        color="var(--subscriber-solid)"
      />
    )
  );
}
