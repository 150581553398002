import React, { useCallback, useState } from "react";

import { CancelSubscriptionDialogView } from "@/feature-subscriber/CancelSubscriptionDialogView.jsx";
import { ManageSubscriptionDialogView } from "@/feature-subscriber/ManageSubscriptionDialogView.jsx";
import { AddCardDialogView } from "@/feature-wallet/AddCardDialogView.jsx";
import { AddPaymentMethodDialogView } from "@/feature-wallet/AddPaymentMethodDialogView.jsx";
import { AddPaypalDialogView } from "@/feature-wallet/AddPayPalDialogView.jsx";
import { DialogWindow } from "@/shared/Dialog.jsx";
import {
  createModalNavigation,
  useModalNavigation,
} from "@/shared/ModalNavigation.jsx";

const ManageSubscriptionNav = createModalNavigation<{
  manageSubscription: void;
  cancelSubscription: void;

  addPaymentMethod: void;
  addCard: void;
  addPayPal: void;
}>("purchase-premium");

export function ManageSubscriptionDialog({
  closeModal,
}: {
  closeModal(): void;
}) {
  const modalRouter = useModalNavigation(
    ManageSubscriptionNav,
    "manageSubscription",
  );

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | null
  >(null);

  const onAddPaymentMethod = useCallback(() => {
    modalRouter.pushRoute("addPaymentMethod");
  }, [modalRouter]);

  const onRequestCancelSubscription = useCallback(() => {
    modalRouter.pushRoute("cancelSubscription");
  }, [modalRouter]);

  const onRequestAddCard = useCallback(() => {
    modalRouter.pushRoute("addCard");
  }, [modalRouter]);

  const onRequestAddPayPal = useCallback(() => {
    modalRouter.pushRoute("addPayPal");
  }, [modalRouter]);

  const onAddedPaymentMethod = useCallback(() => {
    modalRouter.setRoute("manageSubscription");
  }, [modalRouter]);

  const onBack = useCallback(() => {
    modalRouter.popRoute();
  }, [modalRouter]);

  return (
    <DialogWindow width={400}>
      <ManageSubscriptionNav.Navigator
        modalRouter={modalRouter}
        onPopScope={closeModal}
      >
        <ManageSubscriptionNav.Route name="manageSubscription">
          <ManageSubscriptionDialogView
            selectedPaymentMethodId={selectedPaymentMethodId}
            onSelectedPaymentMethod={setSelectedPaymentMethodId}
            onAddPaymentMethod={onAddPaymentMethod}
            onCancelSubscription={onRequestCancelSubscription}
            onUpdated={closeModal}
          />
        </ManageSubscriptionNav.Route>

        <ManageSubscriptionNav.Route name="cancelSubscription">
          <CancelSubscriptionDialogView
            onReturn={onBack}
            onCancelled={closeModal}
          />
        </ManageSubscriptionNav.Route>

        <ManageSubscriptionNav.Route name="addPaymentMethod">
          <AddPaymentMethodDialogView
            onRequestAddCard={onRequestAddCard}
            onRequestAddPayPal={onRequestAddPayPal}
            onPressBack={onBack}
          />
        </ManageSubscriptionNav.Route>

        <ManageSubscriptionNav.Route name="addCard">
          <AddCardDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </ManageSubscriptionNav.Route>

        <ManageSubscriptionNav.Route name="addPayPal">
          <AddPaypalDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </ManageSubscriptionNav.Route>
      </ManageSubscriptionNav.Navigator>
    </DialogWindow>
  );
}
