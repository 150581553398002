import { t } from "i18next";

import { writeSettings } from "@/app/actions.mjs";
import { appURLs } from "@/app/constants.mjs";
import { BUILD_SORT } from "@/game-lol/constants/builds-constants.mjs";
import Static from "@/game-lol/utils/static.mjs";
import * as Lock from "@/inline-assets/blitz-lock.svg";
import { getLocale } from "@/util/i18n-helper.mjs";

export const buildOption = {
  title: ["common:proPlus", "Pro+"],
  activeKey: BUILD_SORT.pro,
  onClick() {
    writeSettings(["lol", "prefersApexBuilds"], true);
  },
};

function placeholderPromoBuild(title) {
  const games = Math.round(450 + Math.random() * 1000);
  const winrate = Math.round(49 + Math.random() * 6);
  return {
    title: t(title),
    subtitle: t("lol:countGame", "{{count}} Game", {
      count: games,
    }),
    keystone: Static.getRuneImage(8010),
    secondaryTree: Static.getRuneImage(8100),
    mainItem: Static.getItemImage(6672),
    winrate: (winrate / 100).toLocaleString(getLocale(), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "percent",
    }),
    winrateColor: "var(--subscriber-solid)",
    isSelected: false,
    unlock: Lock.svg,
    style: `--title-color: var(--subscriber-solid); --blur: blur(6px); pointer-events: none;`,
  };
}

export const inGamePromo = {
  ctaTitle: ["lol:proPlusBuildsPromo.title", "Start Winning More"],
  ctaDescription: [
    "lol:proPlusBuildsPromo.description",
    "With Premium, you can unlock Pro Builds+, which lets you access top-tier pro builds and optimized build suggestions.",
  ],
  unlockCopy: ["common:wallet.unlockWithPremium", "Unlock with Premium"],
  unlockLink: "/premium",
  unlockSections: [
    {
      number: 1,
      title: [
        "lol:proPlusBuildsPromo.tournamentBuilds.title",
        "Tournament Builds",
      ],
      description: [
        "lol:proPlusBuildsPromo.tournamentBuilds.description",
        "Import builds used by top pros in LCS, LCK, and other official tournament matches.",
      ],
      image: `${appURLs.CDN}/blitz/blitz-pro/builds/tournament-builds.webp`,
    },
    {
      number: 2,
      title: [
        "lol:proPlusBuildsPromo.mastersPlusBuilds.title",
        "Masters+ Builds",
      ],
      description: [
        "lol:proPlusBuildsPromo.mastersPlusBuilds.description",
        "Pro Builds+ aggregates builds used in Masters league and above.",
      ],
      image: `${appURLs.CDN}/blitz/blitz-pro/builds/masters-builds2.webp`,
    },
    {
      number: 3,
      title: [
        "lol:proPlusBuildsPromo.dynamicSuggestions.title",
        "Dynamic Suggestions",
      ],
      description: [
        "lol:proPlusBuildsPromo.dynamicSuggestions.description",
        "All Pro Builds+ build suggestions are optimized to maximize team synergy.",
      ],
      image: `${appURLs.CDN}/blitz/blitz-pro/builds/dynamic-suggestions3.webp`,
    },
  ],
  buildsList: [
    placeholderPromoBuild([
      "common:wallet.unlockWithPremium",
      "Unlock with Premium",
    ]),
    placeholderPromoBuild([
      "common:wallet.unlockWithPremium",
      "Unlock with Premium",
    ]),
    placeholderPromoBuild([
      "common:wallet.unlockWithPremium",
      "Unlock with Premium",
    ]),
  ],
};
