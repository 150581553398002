import React from "react";
import type { TFunction } from "i18next";

import { accountRefs } from "@/app/account.refs.mjs";
import { appURLs } from "@/app/constants.mjs";
import { SubscriberButton } from "@/feature-subscriber/Shared.jsx";
import {
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import { RANK_SYMBOL_TO_ICON } from "@/game-lol/constants/constants-rank-icons.mjs";
import type { LolOverlaySettings } from "@/game-lol/definition.mjs";
import LoLDefinition, {
  GAME_SYMBOL_LOL,
  LOL_OVERLAY_SHARED_PREFERENCES,
  LOL_OVERLAY_SHARED_VISIBILITY,
} from "@/game-lol/definition.mjs";
import { getSummonerBestRank } from "@/game-lol/utils/use-summoner-ranks.mjs";
import ValorantDefinition, { GAME_SYMBOL_VAL } from "@/game-val/definition.mjs";
import type {
  BaseGameOverlay,
  OverlaySettingKey,
} from "@/hub-overlays/constants.mjs";
import refs from "@/hub-overlays/constants.mjs";
import SubscriberIcon from "@/inline-assets/pro.svg";
import { writeOverlaySetting } from "@/library/actions.mjs";

function setLolOverlayBestRank(key: OverlaySettingKey) {
  const rank = getSummonerBestRank();
  if (!RANK_SYMBOLS[rank?.tier]) return;
  writeOverlaySetting(GAME_SYMBOL_LOL, key, RANK_SYMBOL_TO_STR[rank.tier].key);
}

const originals = new WeakMap();
export const setupProOverlays = () => inject(overlaysInjects, originals);

export const teardownProOverlays = () => restore(overlaysInjects, originals);
const SHARED_BENCHMARK_HINT: Translation = [
  "overlays:lol.hint.sharedRank",
  "This option is shared between Benchmarking and Jungle Pathing.",
];

const disabledCallback = () => !accountRefs.activeSubscriber;
// TODO: use a correct <ProButton> shared component
const proButtonRenderer = (t: TFunction) =>
  !accountRefs.activeSubscriber
    ? React.createElement(
        SubscriberButton,
        {
          href: "/premium",
          icon: React.createElement(SubscriberIcon, {}),
        },
        t(["common:wallet.unlockWithSubscriber", "Unlock with Premium"]),
      )
    : null;

const LOL_OVERLAYS: Record<string, BaseGameOverlay<LolOverlaySettings>> = {
  benchmark: {
    partlyFree: true,
    key: ["isSummonersRiftOverlayEnabled"],
    title: ["overlays:lol.benchmarking.title", "Benchmarking"],
    tags: ["performance"],
    Preview: React.lazy(
      () => import("@/library/SimulatedOverlaysBenchmarking.jsx"),
    ),
    description: [
      "overlays:lol.benchmarking.description",
      "Track key performance metrics to better gauge performance in real time.",
    ],
    images: {
      large: `${appURLs.CDN}/blitz/ui/img/settings/lolOverlay/benchmarking-portrait.webp`,
      small: `${appURLs.CDN}/blitz/ui/img/settings/lol-benchmarking-min.webp`,
      settings: `${appURLs.CDN}/blitz/ui/img/settings/lol-benchmarking-min.webp`,
      featured: `${appURLs.CDN}/blitz/overlay-marketplace/benchmarking-featured.webp`,
    },
    native: true,
    date: new Date(500),
    features: [
      ...LOL_OVERLAY_SHARED_VISIBILITY,
      ...LOL_OVERLAY_SHARED_PREFERENCES,
      {
        text: [
          "overlays:lol.benchmarking.options.rank.title",
          "Benchmark Options",
        ],
        description: SHARED_BENCHMARK_HINT,
        items: [
          {
            title: [
              "overlays:lol.benchmarking.options.rank.description",
              "Auto Detect Division",
            ],
            text: [
              "overlays:lol.benchmarking.options.rank.hint",
              "Automatically sets the benchmark to one rank higher then your current rank. Unranked players will have the benchmark set at Gold.",
            ],
            toggle: {
              key: ["csBenchmarks", "manualSetDivision"],
              inverse: true,
              onChange: (v) => {
                setLolOverlayBestRank(["csBenchmarks", "leagueDivision"]);
                // these must be the same as junglePathingBenchmarks for some reason
                setLolOverlayBestRank([
                  "junglePathingBenchmarks",
                  "leagueDivision",
                ]);
                writeOverlaySetting(
                  GAME_SYMBOL_LOL,
                  ["junglePathingBenchmarks", "manualSetDivision"],
                  v,
                );
              },
            },
          },
          {
            select: {
              key: ["csBenchmarks", "leagueDivision"],
              options: Object.entries(RANK_SYMBOLS)
                .filter(([s]) => !s.endsWith("Plus"))
                .reverse()
                .map(([optionValue, rankSymbol]) => ({
                  value: optionValue,
                  Icon: RANK_SYMBOL_TO_ICON[rankSymbol],
                })),
              disabled: (s) => !s.csBenchmarks?.manualSetDivision,
              // must be the same as junglePathingBenchmarks for some reason
              onChange: (v) =>
                writeOverlaySetting(
                  GAME_SYMBOL_LOL,
                  ["junglePathingBenchmarks", "leagueDivision"],
                  v,
                ),
            },
          },
        ],
      },
      {
        text: ["overlays:lol.benchmarking.options.csPerMin.title", "CSM"],
        description: [
          "overlays:lol.benchmarking.options.csPerMin.description",
          "Creep Score divided by game minutes",
        ],
        renderProButton: proButtonRenderer,
        toggle: {
          key: ["benchmarking", "isCsPerMinuteEnable"],
        },
        items: [
          {
            disabled: (s) => {
              return !s.benchmarking?.isCsPerMinuteEnable;
            },
            select: {
              key: ["benchmarking", "csPerMinute"],
              options: [
                {
                  value: "legacy",
                  image: `${appURLs.CDN}/blitz/ui/img/settings/benchmarking/simple-light.webp`,
                  text: [
                    "overlays:lol.junglePathing.options.overlayStyle.simple",
                    "Simple",
                  ],
                },
                {
                  value: "detailed",
                  image: `${appURLs.CDN}/blitz/ui/img/settings/benchmarking/detailed-light.webp`,
                  text: [
                    "overlays:lol.benchmarking.options.csPerMin.detailed",
                    "Detailed",
                  ],
                  disabled: disabledCallback,
                },
              ],
            },
          },
        ],
      },
      {
        text: [
          "overlays:lol.benchmarking.options.goldPerMin.title",
          "Gold per Minute",
        ],
        description: [
          "overlays:lol.benchmarking.options.goldPerMin.description",
          "Total gold gained divided by game minutes.",
        ],
        toggle: {
          key: ["benchmarking", "isGoldPerMinuteEnabled"],
        },
      },
      {
        text: [
          "overlays:lol.benchmarking.options.killParticipation.title",
          "Kill Participation",
        ],
        description: [
          "overlays:lol.benchmarking.options.killParticipation.description",
          "Kills and assists as a percentage of all team kills.",
        ],
        toggle: {
          key: ["benchmarking", "isKillParticipationEnabled"],
        },
      },
      {
        text: [
          "overlays:lol.benchmarking.options.championLevel.title",
          "Champion Level",
        ],
        description: [
          "overlays:lol.benchmarking.options.championLevel.description",
          "Current level of your champions.",
        ],
        toggle: {
          key: ["benchmarking", "isChampionLevelEnabled"],
        },
      },
      {
        text: ["overlays:lol.benchmarking.options.kda.title", "KDA"],
        description: [
          "overlays:lol.benchmarking.options.kda.description",
          "Kills and assists divided by deaths.",
        ],
        toggle: {
          key: ["benchmarking", "isKdaEnabled"],
        },
      },
      {
        text: [
          "overlays:lol.benchmarking.options.supportItemTimer.title",
          "Support Item Timer",
        ],
        description: [
          "overlays:lol.benchmarking.options.supportItemTimer.description",
          "Track completion time of support items.",
        ],
        renderProButton: proButtonRenderer,
        toggle: {
          key: ["benchmarking", "isSupportItemTimerEnabled"],
          disabled: disabledCallback,
        },
      },
    ],
  },
  junglePath: {
    partlyFree: true,
    key: ["isJunglePathingOverlayEnabled2023"],
    title: ["overlays:lol.junglePathing.title", "Jungle Pathing"],
    tags: ["tracker"],
    description: [
      "overlays:lol.junglePathing.description",
      "Take the most effective jungle camps clearing route to dominate the game.",
    ],
    conflictDisclaimer: [
      "overlays:lol.junglePathing.disclaimer",
      "Disable League's native jungle pathing.",
    ],
    images: {
      large: `${appURLs.CDN}/blitz/ui/img/settings/lolOverlay/jungle-pathing-portrait.webp`,
      small: `${appURLs.CDN}/blitz/ui/img/settings/lol-jungle-pathing-min.webp`,
    },
    native: true,
    date: new Date(2021, 2, 3),
    Preview: React.lazy(
      () => import("@/library/SimulatedOverlayJunglePathing.jsx"),
    ),
    features: [
      ...LOL_OVERLAY_SHARED_VISIBILITY,
      ...LOL_OVERLAY_SHARED_PREFERENCES,
      {
        text: [
          "overlays:lol.junglePathing.options.rank.title",
          "Benchmark Division",
        ],
        description: SHARED_BENCHMARK_HINT,
        items: [
          {
            title: [
              "overlays:lol.junglePathing.options.rank.description",
              "Auto Detect Division",
            ],
            text: [
              "overlays:lol.junglePathing.options.rank.hint",
              "Automatically sets the benchmark to one rank higher then your current rank. Unranked players will have the benchmark set at Gold.",
            ],
            toggle: {
              key: ["junglePathingBenchmarks", "manualSetDivision"],
              inverse: true,
              onChange: (v) => {
                setLolOverlayBestRank([
                  "junglePathingBenchmarks",
                  "leagueDivision",
                ]);
                // these must be the same as csBenchmarks for some reason
                setLolOverlayBestRank(["csBenchmarks", "leagueDivision"]);
                writeOverlaySetting(
                  GAME_SYMBOL_LOL,
                  ["csBenchmarks", "manualSetDivision"],
                  v,
                );
              },
            },
          },
          {
            select: {
              key: ["junglePathingBenchmarks", "leagueDivision"],
              disabled: (s) => !s.junglePathingBenchmarks.manualSetDivision,
              options: Object.entries(RANK_SYMBOLS)
                .filter(([s]) => !s.endsWith("Plus"))
                .reverse()
                .map(([optionValue, rankSymbol]) => ({
                  value: optionValue,
                  Icon: RANK_SYMBOL_TO_ICON[rankSymbol],
                })),
              onChange: (v) =>
                // must be the same as csBenchmarks for some reason
                writeOverlaySetting(
                  GAME_SYMBOL_LOL,
                  ["csBenchmarks", "leagueDivision"],
                  v,
                ),
            },
          },
        ],
      },
      {
        text: [
          "overlays:lol.junglePathing.options.overlayStyle.title",
          "Overlay Style",
        ],
        renderProButton: proButtonRenderer,
        items: [
          {
            select: {
              key: ["junglePathingBenchmarks", "benchmark"],
              options: [
                {
                  value: "simple",
                  image: `${appURLs.CDN}/blitz/ui/img/settings/junglePathing/simple-light.webp`,
                  text: [
                    "overlays:lol.junglePathing.options.overlayStyle.simple",
                    "Simple",
                  ],
                },
                {
                  value: "detailed",
                  image: `${appURLs.CDN}/blitz/ui/img/settings/junglePathing/detailed-light.webp`,
                  text: [
                    "overlays:lol.junglePathing.options.overlayStyle.detailed",
                    "Detailed",
                  ],
                  disabled: disabledCallback,
                },
              ],
            },
          },
        ],
      },
    ],
  },
};

const LOL_OVERLAY_SETTINGS = {
  junglePathingBenchmarks: {
    ...LoLDefinition.overlaySettings.junglePathingBenchmarks,
    benchmark: accountRefs.activeSubscriber ? "detailed" : "simple",
  },
  benchmarking: {
    ...LoLDefinition.overlaySettings.benchmarking,
    isSupportItemTimerEnabled: accountRefs.activeSubscriber,
  },
};

// TODO: i18n - when implemented/uncommented
const VALORANT_OVERLAYS: Record<string, BaseGameOverlay> = {
  // postMatchInsight: {
  //   key: [NOT_YET_IMPLEMENTED],
  //   title: ["overlays:postmatchInsights.title", "Post Match Insights"],
  //   tags: ["performance"],
  //   description: [
  //     "overlays:postmatchInsights.desc",
  //     "Automatically pop up in-depth statistics as soon as your game ends. The post match overlay can be collapsed into a minimized version, which is draggable and closable after a few seconds. Blitz Pro users unlock the ability to toggle the post-match overlay on/off.",
  //   ],
  //   brief: [
  //     "overlays:postmatchInsights.brief",
  //     "Automatically pop up in-depth statistics as soon as your game ends.",
  //   ],
  //   images: {
  //     large: `${appURLs.CDN}/blitz/ui/img/settings/valOverlay/post-match-portrait.webp`,
  //     small: `${appURLs.CDN}/blitz/ui/img/settings/val-post-match-insights-min.webp`,
  //   },
  //   // features: [
  //   //   {
  //   //     toggle: { key: [NOT_YET_IMPLEMENTED] },
  //   //     text: ["overlays:postmatchInsights.feature.analysis", "Analysis"],
  //   //   },
  //   //   {
  //   //     toggle: { key: [NOT_YET_IMPLEMENTED] },
  //   //     text: ["overlays:postmatchInsights.feature.hitboxGraph", "Hitbox Graph"],
  //   //   },
  //   //   {
  //   //     toggle: {
  //   //       key: [NOT_YET_IMPLEMENTED],
  //   //     },
  //   //     text: [
  //   //       "overlays:postmatchInsights.feature.recentAvgOfBenchmark",
  //   //       "Benchmark of your recent average",
  //   //     ],
  //   //   },
  //   //   {
  //   //     toggle: { key: [NOT_YET_IMPLEMENTED] },
  //   //     text: [
  //   //       "overlays:postmatchInsights.feature.weaponssStats",
  //   //       "Weapons statistics",
  //   //     ],
  //   //   },
  //   //   {
  //   //     toggle: { key: [NOT_YET_IMPLEMENTED] },
  //   //     text: [
  //   //       "overlays:postmatchInsights.feature.abilitiesStats",
  //   //       "Abilities statistics",
  //   //     ],
  //   //   },
  //   //   {
  //   //     toggle: { key: [NOT_YET_IMPLEMENTED] },
  //   //     text: ["overlays:postmatchInsights.feature.scoreboard", "Scoreboard"],
  //   //   },
  //   // ],
  //   date: new Date(500),
  // },
};

for (const [game, overlays] of [
  [GAME_SYMBOL_LOL, LOL_OVERLAYS],
  [GAME_SYMBOL_VAL, VALORANT_OVERLAYS],
]) {
  Object.entries(overlays).forEach(([id, item]) => {
    item["game"] = game;
    item["id"] = id;
    if (!item.partlyFree) {
      item.disabled = disabledCallback;
      item.tags.unshift("subscriber");
    }
  });
}

// TODO: use the correct <SubscriberTag>
const OVERLAY_TAGS = {
  subscriber: {
    text: ["common:subscriber", "Premium"],
    color: "var(--subscriber-solid)",
  },
};

// ARRAYS, note that arrays need to copy to preserve original elements
const FEATURED_OVERLAYS = [
  LOL_OVERLAYS.benchmarking,
  ...refs.FEATURED_OVERLAYS,
];

const overlaysInjects = [
  [refs, { FEATURED_OVERLAYS, OVERLAY_TAGS }],
  [LoLDefinition, { overlays: LOL_OVERLAYS }],
  [LoLDefinition, { overlaySettings: LOL_OVERLAY_SETTINGS }],
  [ValorantDefinition, { overlays: VALORANT_OVERLAYS }],
];

function inject(injectEntries, originalsMap) {
  for (const [ref, injects] of injectEntries) {
    const og = {};
    originalsMap.set(ref, og);
    for (const [CONSTANT, constValue] of Object.entries(injects)) {
      og[CONSTANT] = { ...ref[CONSTANT] };
      Object.assign(ref[CONSTANT], constValue);
    }
  }
}
function restore(injectEntries, originalsMap) {
  for (const [ref] of injectEntries) {
    const og = originalsMap.get(ref);
    if (!og) continue;
    for (const [CONSTANT, ogValue] of Object.entries(og)) {
      for (const [k] of Object.entries(ref[CONSTANT])) {
        // if the value did not exist in the original, it was added by the inject so delete it
        if (ogValue[k]) {
          ref[CONSTANT][k] = ogValue[k];
          continue;
        }
        delete ref[CONSTANT][k];
      }
    }
  }
}
